import axios from "axios";
import Web3 from "web3";
// import fetch from "node-fetch";
// const API_BASE_URI = "https://api.1inch.io/v4.0/137";
// const broadcastApiUrl = 'https://tx-gateway.1inch.io/v1.1/137/broadcast';

// // eth 
const API_BASE_URI = "https://api.1inch.io/v4.0/1";
const broadcastApiUrl = 'https://tx-gateway.1inch.io/v1.1/1/broadcast';

function apiRequestUrl(methodName, queryParams) {
    return API_BASE_URI + methodName + '?' + (new URLSearchParams(queryParams)).toString();
}

export const getTokens = () => {
    return axios.get(`${API_BASE_URI}/tokens`);
}


export const getQuote = (quoteParams) => {
    const url = apiRequestUrl('/quote', quoteParams);
    return axios.get(url);
}

export const doSwap = (fromTokenAddress, toTokenAddress, fromTokenAmount, metaMaskAccount) => {
    axios.get(`${API_BASE_URI}/swap?fromTokenAddress=${fromTokenAddress}&toTokenAddress=${toTokenAddress}&amount=${fromTokenAmount}&fromAddress=${metaMaskAccount}&slippage=1`);
}

export const approveSpender = () => {
    return axios.get(`${API_BASE_URI}/approve/spender`);
}

export const getAllowence = (tokenAddress, walletAddress) => {
    return axios.get(apiRequestUrl('/approve/allowance', { tokenAddress, walletAddress }));
}

export const buildTxForApproveTradeWithRouter = async (walletAddress, tokenAddress, amount) => {
    let dataURL = `${API_BASE_URI}/approve/transaction?tokenAddress=${tokenAddress}&amount=${amount}`;
    const web3 = new Web3(window.ethereum);
    const transaction = await fetch(dataURL).then(res => res.json());
    const gasLimit = await web3.eth.estimateGas({
        ...transaction,
        from: walletAddress
    });
    return {
        ...transaction,
        gas: gasLimit.toString()

    };
}

// 0x0Ec72CD6690db40B16be166858299F19D4f8E5B0
export const buildTxForSwap = async (swapParams) => {
    const url = apiRequestUrl('/swap', swapParams);
    return axios.get(url)
}

export async function broadCastRawTransaction(rawTransaction) {
    return fetch(broadcastApiUrl, {
        method: 'post',
        body: JSON.stringify({ rawTransaction }),
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(res => {
            return res.transactionHash;
        });
}

export const getTokenDetails = (address) => {
    return axios.get(`https://api.1inch.io/v4.0/1/quote?fromTokenAddress=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&toTokenAddress=${address}&amount=10000000000000000`)
}