import {combineReducers} from "redux";
import * as keys from "./featureKeys"
import  demoReducer from './reducers/demoReducers';
import oneInchReducer from "./reducers/oneInchReducer";
import authReducer from "./reducers/authReducers";

 const rootReducer = combineReducers({
    [keys.demoFeatureKey] : demoReducer,
    [keys.authFeatureKey] : authReducer,
    [keys.oneInchFeatureKey] : oneInchReducer,
});
export {rootReducer};

