import * as types from "../types/demo"

const initState = {
    demo:{
        title:"demo"
    }
};

const demoReducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.DEMO_ACTION:
            return {
                ...state,
                isLoading: true
            }
        case types.DEMO_ACTION_SUCCESS:
            return {
                ...state,
                nfts: payload.data,
                isLoading: false,
            }
        case types.DEMO_ACTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            }
        default: return state;
    }

}

export default demoReducer;