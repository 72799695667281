import React from 'react'

const NotFound = () => {
  return (
    <div className="h-100 mt-5 pt-5  d-flex justify-content-center align-items-center">
      <div className="mt-5 pt-5  display-3 text-danger">404 page not found</div>
    </div>
  );
}

export default NotFound