import * as types from "../types/auth"

const initState = {
    isLoading:false,
    account:"",
    signer:null,
    provider:null,
    errorMessage:""
};

const authReducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.SET_AUTH:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_AUTH_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading:false
            }
        case types.SET_AUTH_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            }
        default: return state;
    }

}

export default authReducer;