import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as oneInchActions from "../../store/actions/oneInchActions";
import Form from "./form";
import "./index.css";

const Home = ({ account, web3Handler }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(oneInchActions.getTokens());
  }, []);
  
  return (
    <>
      <button>change chain</button>
      <Form account={account} web3Handler={web3Handler}/>
    </>
  );
};
export default Home;
