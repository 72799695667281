import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as storeKey from "../../store/featureKeys";
import * as oneInchActions from "../../store/actions/oneInchActions";
import * as oneInchService from "../../services/oneInchService";
import Marquee from "react-fast-marquee";
import * as helpers from "./helper";
import { ethers } from "ethers";
import "./form.css";
import Web3 from "web3";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import CurrencyTokenModal from "../../components/CurrencyTokenModal";
import SlippageModal from "../../components/SlippageModal";
import erc20JSON from "./../../abis/ERC20.json";
import { AlphaRouter } from "@uniswap/smart-order-router";
import {
  Token,
  CurrencyAmount,
  TradeType,
  Percent,
  Ether,
} from "@uniswap/sdk-core";
import JSBI from "jsbi";
import Settingpopup from "../../components/Modal/Settingpopup";
const refererAddress = "0xa3998ffeA36240f51496C3dAbF159F428d37d953";
const fee = 0.2;

const DEFAULT_FORM_STATE = {
  fromTokenAddress: "",
  fromTokenAmount: "",
  toTokenAddress: "",
  fromTokenDecimals: 0,
  toTokenDecimals: 0,
  refererAddress,
  fee,
  gasPrice: "12500000000",
};

const Form = (props) => {
  const [open, setOpen] = useState(false);
  const [exchangeArrow, setExchangeArrow] = useState(false);
  const [showTokenResult, setShowTokenResult] = useState(false);
  const dispatch = useDispatch();
  const [formState, setformState] = useState(DEFAULT_FORM_STATE);
  const { tokens, quote, quoteLoading, errorMessage } = useSelector(
    (state) => state[storeKey.oneInchFeatureKey]
  );

  const handleShowResult = () => setShowTokenResult(!showTokenResult);

  const { account, signer, provider } = useSelector(
    (state) => state[storeKey.authFeatureKey]
  );

  const [isFrom, setisFrom] = useState(false);
  const handleCurrencyModal = (isfrom) => {
    setisFrom(isfrom);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    // console.log({ formState });
    const {
      fromTokenAddress,
      toTokenAddress,
      fromTokenAmount,
      fromTokenDecimals,
    } = formState;

    if (
      fromTokenAddress !== "" && // "" !== "sdducsy"
      toTokenAddress !== "" &&
      fromTokenAmount !== "" &&
      fromTokenDecimals !== 0
    ) {
      let amount = ethers.utils.parseUnits(
        fromTokenAmount.toString(),
        fromTokenDecimals
      );
      if (amount > 0) {
        dispatch(
          oneInchActions.getQuote({
            ...formState,
            fromTokenAddress: formState.fromTokenAddress,
          })
        );
      }
    }
  }, [formState]);

  const checkAllowance = async (tokenAddress, walletAddress) => {
    let allowance = await oneInchService.getAllowence(
      tokenAddress,
      walletAddress
    );
    // console.log("Allowenve", allowance.data);
    return allowance.data.allowance;
  };

  // latest functions 
  const [fromToken, setfromToken] = useState(null);
  const [toToken, settoToken] = useState(null);
  const [fromTokenBalance, setfromTokenBalance] = useState(null);
  const [disabled, setdisabled] = useState(true);
  const [swapLoading, setswapLoading] = useState(false);
  const [slippage, setslippage] = useState(1);
  const [openSlippageModal, setopenSlippageModal] = useState(false);

  // const handleExchange = () => ;

  const handleExchange = () =>{
    setExchangeArrow(!exchangeArrow)
    let token = fromToken;
    setfromToken(toToken);
    settoToken(token);
    if(fromToken && toToken){
      setformState(prevState=>{
        return {
          ...prevState,
          fromTokenAddress:toToken.address,
          fromTokenDecimals:toToken.decimals,
          toTokenAddress:token.address,
          toTokenDecimals:token.decimals
        }
      })
    }else if(fromToken && !toToken){
      setformState(prevState=>{
        return {
          ...prevState,
          fromTokenAddress:"",
          fromTokenDecimals:0,
          toTokenAddress:token.address,
          toTokenDecimals:token.decimals
        }
      })
    }else if(!fromToken && toToken){
      setformState(prevState=>{
        return {
          ...prevState,
          fromTokenAddress:toToken.address,
          fromTokenDecimals:toToken.decimals,
          toTokenAddress:"",
          toTokenDecimals:0
        }
      })
    }
  }
  useEffect(() => {
    if (tokens) {
      const nativeToken = tokens["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"];
      setfromToken(nativeToken);
      selectToken(nativeToken, true);
    }
  }, [tokens]);

  const selectToken = (token, isFrom) => {
    const { decimals, address } = token;
    // console.log({ isFrom });
    if (isFrom) {
      setformState((prevState) => {
        return {
          ...prevState,
          fromTokenAddress: address,
          fromTokenDecimals: decimals,
        };
      });
      setfromToken(token);
    } else {
      setformState((prevState) => {
        return {
          ...prevState,
          toTokenAddress: address,
          toTokenAmount: decimals,
        };
      });
      settoToken(token);
    }
    if (token.hasOwnProperty("isCustom")) {
      setslippage((prevState) => prevState + 5);
    }
    onCloseModal();
  };

  const updateFromTokenAmount = (e) => {
    // console.log("inputChangeHandler ", e.target.value);
    if (e.target.value !== "") {
      setformState({
        ...formState,
        [e.target.name]: e.target.value,
        // [e.target.name]: ethers.BigNumber.from(e.target.value),
      });
    } else {
      setformState({ ...formState, [e.target.name]: "" });
    }
  };

  const getValueOFQuote = () => {
    if (formState.fromTokenAmount !== "") {
      if (quote) {
        let number = ethers.utils.formatUnits(
          quote.toTokenAmount.toString(),
          quote.toToken.decimals
        );
        // console.log({numberRaw:number})
        return number;
      } else {
        return "0.0";
      }
    } else {
      return "0.0";
    }
  };

  const isContinue = () => {
    if (account) {
      if (!toToken) {
        // alert("select a token");
        setdisabled(true);
        return false;
      } else {
        if (formState.fromTokenAmount === "") {
          // alert("enter an amount");
          setdisabled(true);
          return false;
        } else {
          if (quote) {
            let decimals =
              formState.fromTokenDecimals === 0
                ? 18
                : formState.fromTokenDecimals;
            let currentbal = parseFloat(
              ethers.utils.formatUnits(fromTokenBalance.toString(), decimals)
            );
            // console.log({
            //   currentbal,
            //   enterdAmount,
            // });
            if (currentbal >= parseInt(formState.fromTokenAmount)) {
              setdisabled(false);
              return true;
            } else {
              // alert("insufficient token balance");
              setdisabled(true);
              return false;
            }
          } else {
            setdisabled(false);
            return true;
          }
        }
      }
    } else {
      // alert("connect wallet");
      setdisabled(true);
      return false;
    }
  };

  useEffect(() => {
    isContinue();
  }, [formState, account, quote, toToken, fromToken]);

  useEffect(() => {
    const get = async () => {
      if (account !== "") {

        // console.log("Hello I am called");
        const balance = await helpers.getBalanceOfToken(
          formState.fromTokenAddress,
          signer,
          account
        );
        if (balance) {
          setfromTokenBalance(balance);
        }
      }
    };
    get();
  }, [formState.fromTokenAddress, account, fromToken]);

  const formatBalance = (amount, decimals) => {
    // console.log({amount,decimals})
    let formattedBalance = parseFloat(
      ethers.utils.formatUnits(amount.toString(), decimals)
    ).toFixed(5);
    // console.log({formattedBalance})
    return formattedBalance;
  };

  const doSwap = async () => {
    try {
      if (!quote) {
        // console.log("qoute not available");
        return;
      }

      const amountInLeastUnit = ethers.utils.parseUnits(
        formState.fromTokenAmount.toString(),
        formState.fromTokenDecimals
      );
      //fromTokenAddress, toTokenAddress, amount, fromAddress, slippage, referrerAddress, fee
      let currentSlippage;
      if (toToken && toToken.hasOwnProperty("isCustom")) {
        currentSlippage = slippage + 5;
      } else {
        currentSlippage = slippage;
      }
      const swapParams = {
        fromTokenAddress: formState.fromTokenAddress,
        toTokenAddress: formState.toTokenAddress,
        amount: amountInLeastUnit,
        fromAddress: account,
        slippage: currentSlippage,
        referrerAddress: refererAddress,
        fee: fee,
      };

      // console.log({ swapParams });
      setswapLoading(true);
      if (
        formState.fromTokenAddress !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        let allowance = await checkAllowance(
          formState.fromTokenAddress,
          account
        );
        // console.log({
        //   allowance: parseFloat(allowance.toString()),
        //   fromTokenAmount: parseFloat(amountInLeastUnit),
        // });
        // if not enough allowance
        if (parseFloat(allowance.toString()) < parseFloat(amountInLeastUnit)) {
          // console.log(
          //   "allowance not available please make allowance transaction"
          // );
          let transactionForSign = await oneInchService.buildTxForApproveTradeWithRouter(
            account,
            formState.fromTokenAddress,
            amountInLeastUnit
          );
          const web3 = new Web3(window.ethereum);
          // using the promise
          web3.eth
            .sendTransaction({
              ...transactionForSign,
              from: account,
            })
            .then(async function(receipt) {
              // console.log("transactionForApprove sent success ", { receipt });
              const swapTransaction = await oneInchService.buildTxForSwap(
                swapParams
              );
    
              const web3 = new Web3(window.ethereum);
              // using the promise
              web3.eth
                .sendTransaction({
                  ...swapTransaction,
                })
                .then(function(receipt) {
                  // console.log("tx sent success ", { receipt });
                  setswapLoading(false);
                })
                .catch(function(err) {
                  console.log("error in WEB3 sendTX ", err.message);
                  setswapLoading(false);
                });
            })
            .catch(function(err) {
              // console.log(
              //   "error in transactionForApprove sendTX ",
              //   err.message
              // );
              setswapLoading(false);
            });
        } else {
          // if enough allowance
          let response;
          try {
            response = await oneInchService.buildTxForSwap(swapParams);
          } catch (error) {
            setswapLoading(false);
            // console.log("ERRROR IN SWAP ", error.response.data.description);
            alert(error.response.data.description);
            return;
          }

          // console.log("swapTransaction >>", response.data);
          const { tx } = response.data;
          const web3 = new Web3(window.ethereum);
          // using the promise
          web3.eth
            .sendTransaction({
              ...tx,
            })
            .then(function(receipt) {
              // console.log("tx sent success ", { receipt });
              setswapLoading(false);
            })
            .catch(function(err) {
              // console.log("error in WEB3 sendTX ", err.message);
              setswapLoading(false);
            });
        }
      } else {
        // 0x0Ec72CD6690db40B16be166858299F19D4f8E5B0
        let response;
        try {
          response = await oneInchService.buildTxForSwap(swapParams);
        } catch (error) {
          setswapLoading(false);
          // console.log("ERRROR IN SWAP ", error.response.data.description);
          alert(error.response.data.description);
          return;
        }
        // console.log("swapTransaction >>", response.data);
        const { tx } = response.data;
        const web3 = new Web3(window.ethereum);
        // using the promise
        web3.eth
          .sendTransaction({
            ...tx,
          })
          .then(function(receipt) {
            // console.log("tx sent success ", { receipt });
            setswapLoading(false);
          })
          .catch(function(err) {
            // console.log("error in WEB3 sendTX ", err.message);
            setswapLoading(false);
          });
      }
    } catch (error) {
      // console.log("doSwap error ", error.message);
      setswapLoading(false);
    }
  };

  const getSwapBtnText = () => {
    let value = "";
    if (account) {
      if (!toToken) {
        value = "select a token";
      } else {
        if (formState.fromTokenAmount === "") {
          value = "enter an amount ";
        } else {
          if (quote) {
            let decimals =
              formState.fromTokenDecimals === 0
                ? 18
                : formState.fromTokenDecimals;
            let currentbal = parseFloat(
              ethers.utils.formatUnits(fromTokenBalance.toString(), decimals)
            );
           
            if (currentbal >= formState.fromTokenAmount) {
              value = "swap";
            } else {
              value = "insufficient token balance";
            }
          } else {
            value = "swap";
          }
        }
      }
    } else {
      value = "connect wallet";
    }
    return value;
  };

  const sellCustomToken = async () => {
    try {
      setswapLoading(true);
      // console.log("sellCustomToken call");
      const fromTokenData = {
        name: fromToken.name,
        symbol: fromToken.symbol,
        decimals: fromToken.decimals,
        address: fromToken.address,
      };

      // 0x0Ec72CD6690db40B16be166858299F19D4f8E5B0
      let toTokenData;
      if (
        formState.toTokenAddress ===
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        let nativeToken = new Ether(1);
        // console.log({ nativeToken });
        toTokenData = {
          name: nativeToken.name,
          symbol: nativeToken.symbol,
          decimals: nativeToken.decimals,
          address: nativeToken.wrapped.address,
        };
      } else {
        toTokenData = {
          name: toToken.name,
          symbol: toToken.symbol,
          decimals: toToken.decimals,
          address: toToken.address,
        };
      }

      const chainId = 1;
      const totoken = new Token(
        chainId,
        toTokenData.address,
        toTokenData.decimals,
        toTokenData.symbol,
        toTokenData.name
      );

      const fromtoken = new Token(
        chainId,
        fromTokenData.address,
        fromTokenData.decimals,
        fromTokenData.symbol,
        fromTokenData.name
      );
      // console.log({
      //   fromtoken,
      //   totoken,
      // });
      const router = new AlphaRouter({ chainId: chainId, provider: provider });

      // console.log({ slippage });
      const slippagePercent = new Percent(40, 100);
      const wei = ethers.utils.parseUnits(
        formState.fromTokenAmount.toString(),
        fromtoken.decimals
      );

      console.log({ wei: wei.toString() });
      const currencyAmount = CurrencyAmount.fromRawAmount(
        fromtoken,
        JSBI.BigInt(wei)
      );

      const route = await router.route(
        currencyAmount,
        totoken,
        TradeType.EXACT_INPUT,
        {
          recipient: account,
          slippageTolerance: slippagePercent,
          deadline: Math.floor(Date.now() / 1000) + 60 * 20,
        }
      );

      // build tx now
      const v3_swap_router_address =
        "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45";
      // const unWrapData = "4449404b7c00000000000000000000000000000000000000000000000000000ad1e631c46400000000000000000000000082c9f8db3cb5fc7a4571e7923fbece6f84110e72"
      const transaction = {
        data: route.methodParameters.calldata,
        to: v3_swap_router_address,
        value: ethers.BigNumber.from(route.methodParameters.value),
        from: account,
        gasPrice: ethers.BigNumber.from(route.gasPriceWei),
        gasLimit: ethers.utils.hexlify(1000000),
      };

      // const quoteAmountOut = route.quote.toFixed(6);
      // const ratio = (quoteAmountOut / formState.fromTokenAmount).toFixed(3);
      const approvalAmount = ethers.utils
        .parseUnits(formState.fromTokenAmount.toString(), fromtoken.decimals)
        .toString();

      // console.log({ approvalAmount });
      const fromTokenContract = new ethers.Contract(
        fromTokenData.address,
        erc20JSON.abi,
        provider
      );
      await fromTokenContract
        .connect(signer)
        .approve(v3_swap_router_address, approvalAmount);

      const tx = await signer.sendTransaction(transaction);
      setswapLoading(false);
      console.log("tx completed", tx);
      // await fromTokenContract
      //   .connect(signer)
      //   .approve(v3_swap_router_address, approvalAmount);
      // const web3 =  new Web3(window.ethereum);

      // web3.eth
      //   .sendTransaction({
      //     ...transaction,
      //   })
      //   .then(function(receipt) {
      //     console.log("tx sent success ", { receipt });
      //     setswapLoading(false);
      //   })
      //   .catch(function(err) {
      //     console.log("error in WEB3 sendTX ", err);
      //     setswapLoading(false);
      //   });

    } catch (error) {
      setswapLoading(false);
      // console.log("SellCxustom Token error >", error);
    }
  };
  const routeSwap = () => {
    if (!quote) {
      // console.log("qoute not available");
      return;
    }
    if (fromToken && fromToken.hasOwnProperty("isCustom")) {
      // get commision
      sellCustomToken();
    } else {
      doSwap();
    }
  };
  if (!signer) {
    return <h1>hello ji not conect</h1>;
  }
  const [showResults, setShowResults] = useState(false);
  const settingopentoggle = () => {
    setShowResults((showResults) => !showResults);
  };
  return (
    <>
      <div className="navigation-button-container marquee-slide">
        <Marquee speed={30}>
          <ul>
            {tokens &&
              Object.values(tokens)
                .slice(0, 10)
                .map((token) => {
                  const { name, logoURI, address } = token;
                  return (
                    <li
                      onClick={() => selectToken(token, isFrom)}
                      key={address}
                      className="mr-3"
                    >
                      <img src={logoURI} alt="currency icon" />{" "}
                      <span>{name}</span>
                    </li>
                  );
                })}
          </ul>
        </Marquee>
      </div>
      <div className="swap-main-container">
        <div className="swap-container">
          <h1>
            <strong>Swap</strong>
            {/* <button onClick={swapWithUniswap}>swap-uniswap</button> */}
          </h1>
          <button
            type="button"
            className="btn-setting"
            // onClick={() => setopenSlippageModal(true)}
            onClick={settingopentoggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="sc-1ndknrv-0 lbfZs"
            >
              <circle cx="12" cy="12" r="3"></circle>
              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
            </svg>
          </button>
        </div>
        {showResults ? (
          <Settingpopup
            fromToken={fromToken}
            slippage={slippage}
            setslippage={setslippage}
          />
        ) : null}

        <div className="exchange-wrapper-sec">
          <div className="swap-input-container">
            <input
              className="swap-amount-input"
              placeholder="0.0"
              type="number"
              name="fromTokenAmount"
              onChange={updateFromTokenAmount}
              value={formState.fromTokenAmount.toString()}
            />
            <div
              className={
                fromToken
                  ? "curruncy-modal-wrap selected-token"
                  : "curruncy-modal-wrap"
              }
            >
              <button onClick={() => handleCurrencyModal(true)}>
                {fromToken ? (
                  <div>
                    <img src={fromToken.logoURI} alt="token" />
                    {fromToken.symbol}
                  </div>
                ) : (
                  "Select token"
                )}
                <KeyboardArrowDownIcon />
              </button>
              {fromToken && (
                <span className="balance">
                {" "}
                Balance:
                {fromTokenBalance
                  ? formatBalance(fromTokenBalance, fromToken.decimals)
                  : " 0.0"}
              </span>
              )}
            </div>
            <CurrencyTokenModal
              isFrom={isFrom}
              selectToken={selectToken}
              fromToken={fromToken}
              toToken={toToken}
              tokens={tokens}
              open={open}
              onCloseModal={onCloseModal}
            />
            <SlippageModal
              slippage={slippage}
              setslippage={setslippage}
              open={openSlippageModal}
              onCloseModal={() => setopenSlippageModal(false)}
            />
          </div>
          <div
            className={
              exchangeArrow ? "arrow-exchange rotateUp" : "arrow-exchange"
            }
            onClick={handleExchange}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#6E727D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <polyline points="19 12 12 19 5 12"></polyline>
            </svg>
          </div>
          <div className="swap-input-container">
            <input
              className="swap-amount-input"
              placeholder="0"
              type="number"
              name="toTokenAddress"
              readOnly={true}
              value={quote ? getValueOFQuote() : "0.0"}
            />
            <div
              className={
                toToken
                  ? "curruncy-modal-wrap  selected-token"
                  : "curruncy-modal-wrap "
              }
            >
              <button onClick={() => handleCurrencyModal(false)}>
                {toToken ? (
                  <div>
                    <img src={toToken.logoURI} alt="token" />
                    {toToken.symbol}
                  </div>
                ) : (
                  "Select token"
                )}
                <KeyboardArrowDownIcon />
              </button>
            </div>
          </div>
        </div>
        <>
          {errorMessage !== "" && (
            <div>
              <p style={{ color: "red" }}>{errorMessage}</p>
            </div>
          )}
        </>
        <div className="calc-bottom-sec">
          {quoteLoading && (
            <div className="loading-section">
              <p>
                <svg
                  className="svgLoader"
                  viewBox="0 0 1024 1024"
                  width="10em"
                  height="10em"
                >
                  <path fill="lightblue" d="PATH FOR THE LOADER ICON" />
                </svg>
                <span className="ml-2">fetching best price...</span>
              </p>
            </div>
          )}
        </div>
        <div
          className={
            showTokenResult
              ? "calc-bottom-sec activeCollapse"
              : "calc-bottom-sec"
          }
        >
          <div className="calculating-value">
            <span>
              <ErrorOutlineIcon />
            </span>
            <button title="1 ETH = 0.07884 WBTC">
              <span>1 ETH = 0.07884 WBTC </span>
              <span>($1,709.02)</span>
            </button>
          </div>
          <div className="calculate-perct">
            <button>
              <LocalGasStationIcon />
              <span>$1.19</span>
            </button>
            <span onClick={handleShowResult}>
              <KeyboardArrowDownIcon />
            </span>
          </div>
        </div>
        <div
          className={showTokenResult ? "swap-collapse show" : "swap-collapse"}
        >
          <div className="swap-result-table">
            <div className="list-tr">
              <p title="Expected Output">
                Expected Output <span>333 ETH</span>
              </p>
              <p>
                Price Impact<span className="orange-text">-4.30%</span>
              </p>
              <div className="divider2"></div>
              <p>
                <span>Maximum sent after slippage (0.50%)</span>{" "}
                <span>800410 1INCH</span>
              </p>
              <p>
                <span>Network Fee</span>
                <span>~$6.77</span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div className="ml-12 w-100">
            <div className="d-flex w-100 justify-content-center">
              <button
                disabled={disabled}
                onClick={routeSwap}
                className="btn text-uppercase select-token primary"
              >
                {getSwapBtnText()}
              </button>
            </div>
          </div>
          <div>
            {swapLoading && (
              <div className="loading-section">
                <p>
                  <svg
                    className="svgLoader"
                    viewBox="0 0 1024 1024"
                    width="10em"
                    height="10em"
                  >
                    <path fill="lightblue" d="PATH FOR THE LOADER ICON" />
                  </svg>
                  <span className="ml-2 text-white ">swapping...</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
