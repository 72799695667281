import * as types from "../types/oneInch"

const initState = {
    tokens: null,
    isLoading: false,
    quoteLoading: false,
    swapLoading: false,
    errorMessage: ""
};

const oneInchReducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.FETCH_TOKENS:
            return {
                ...state,
                isLoading: true
            }
        case types.FETCH_TOKENS_SUCCESS:
            return {
                ...state,
                tokens: payload,
                isLoading: false,
                errorMessage:""
            }
        case types.FETCH_TOKENS_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            }

        case types.FETCH_QUOTE:
            return {
                ...state,
                quoteLoading: true
            }
        case types.FETCH_QUOTE_SUCCESS:
            return {
                ...state,
                quote: payload,
                quoteLoading: false,
                errorMessage: ""
                
            }
        case types.FETCH_QUOTE_FAILURE:
            return {
                ...state,
                quoteLoading: false,
                errorMessage: payload
            }

        case types.DO_SWAP:
            return {
                ...state,
                swapLoading: true

            }
        case types.DO_SWAP_SUCCESS:
            return {
                ...state,
                swap: payload,
                swapLoading:false
            }

        case types.DO_SWAP_FAILURE:
            return {
                ...state,
                swapLoading:false,
                errorMessage: payload
            }


        case types.APPROVE_SPENDER:
            return {
                ...state,
                isLoading: true

            }
        case types.APPROVE_SPENDER_SUCCESS:
            return {
                ...state,
                approved_spender: payload,
                isLoading: false
            }

        case types.APPROVE_SPENDER_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            }

        case types.GET_ALLOWENCE:
            return {
                ...state,
                isLoading: true

            }
        case types.GET_ALLOWENCE_SUCCESS:
            return {
                ...state,
                approved_allowence: payload,
                isLoading: false
            }

        case types.GET_ALLOWENCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: payload
            }

        default: return state;
    }

}

export default oneInchReducer;