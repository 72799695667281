
import * as types from "../types/auth"
// import * as demoService from "../../services/demoService"

export const setAuth = (data)=>{
    return async (dispatch)=>{
        try {
            dispatch({type:types.SET_AUTH})
            // let response = await demoService.demoAPICall();
            dispatch({type:types.SET_AUTH_SUCCESS,payload:data})
        }catch (e) {
            dispatch({type:types.SET_AUTH_FAILURE,payload:e.message})
        }
    }
}
