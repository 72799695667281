
import * as types from "../types/oneInch"
import * as oneInchService from "../../services/oneInchService"
// import Web3 from "web3";
import { ethers } from "ethers";

export const getTokens = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: types.FETCH_TOKENS })
            let response = await oneInchService.getTokens();
            dispatch({ type: types.FETCH_TOKENS_SUCCESS, payload: response.data.tokens })
        } catch (e) {
            dispatch({ type: types.FETCH_TOKENS_FAILURE, payload: e.message })
        }
    }
}

export const getQuote = (quoteData) => {
    return async (dispatch) => {
        try {
            const { fromTokenAddress, toTokenAddress, fromTokenAmount, fromTokenDecimals, fee } = quoteData;
            const quoteParams = {
                fromTokenAddress,
                toTokenAddress,
                amount: ethers.utils.parseUnits(fromTokenAmount.toString(),fromTokenDecimals),
                fee
            }
            dispatch({ type: types.FETCH_QUOTE });
            let response = await oneInchService.getQuote(quoteParams);
            console.log({ QuoteData: response.data })
            dispatch({ type: types.FETCH_QUOTE_SUCCESS, payload: response.data })
        } catch (e) {
            dispatch({ type: types.FETCH_QUOTE_FAILURE, payload: e.response.data.description })
        }
    }
}

export const doSwap = (fromAddress, toTokenAddress, amount, metaMaskAccount) => {
    return async (dispatch) => {
        try {
            dispatch({ type: types.DO_SWAP })
            let response = await oneInchService.doSwap(fromAddress, toTokenAddress, amount, metaMaskAccount);
            console.log(response.data)
            dispatch({ type: types.DO_SWAP_SUCCESS, payload: response.data })// data wiil come in 400 error beavcuse token is not regiusterd

        } catch (e) {
            dispatch({ type: types.DO_SWAP_FAILURE, payload: e.message })
        }
    }
}


export const approveSpender = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: types.APPROVE_SPENDER })
            let response = await oneInchService.approveSpender();
            console.log(response.data)
            dispatch({ type: types.APPROVE_SPENDER_SUCCESS, payload: response.data })// data wiil come in 400 error beavcuse token is not regiusterd
        } catch (e) {
            dispatch({ type: types.APPROVE_SPENDER_FAILURE, payload: e.message })
        }
    }
}



export const getAllowence = (tokenAddress, walletAddress) => {
    return async (dispatch) => {
        try {
            dispatch({ type: types.GET_ALLOWENCE })
            let response = await oneInchService.getAllowence(tokenAddress, walletAddress);
            console.log(response.data)
            dispatch({ type: types.GET_ALLOWENCE_SUCCESS, payload: response.data })// data wiil come in 400 error beavcuse token is not regiusterd

        } catch (e) {
            dispatch({ type: types.GET_ALLOWENCE_FAILURE, payload: e.message })
        }
    }
}

