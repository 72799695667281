import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import * as oneInchService from "../services/oneInchService";
const CurrencyTokenModal = ({
  selectToken,
  isFrom,
  tokens,
  onCloseModal,
  open,
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [value, tokenvalue] = useState([]);
  const [loading, setloading] = useState(false);
  const [searchedToken, setsearchedToken] = useState(null);
  useEffect(() => {
    if (!tokens) return;
    let filteredData = Object.values(tokens).filter((t) => {
      if (
        searchInputValue &&
        t.name.toLowerCase().indexOf(searchInputValue.toLowerCase()) > -1
      )
        return true;
      if (
        searchInputValue &&
        t.symbol.toLowerCase().indexOf(searchInputValue.toLowerCase()) > -1
      )
        return true;
      if (
        searchInputValue &&
        t.address.toLowerCase().indexOf(searchInputValue.toLowerCase()) > -1
      )
        return true;
      return false;
    });
    tokenvalue(filteredData);
  }, [searchInputValue]);

  useEffect(() => {
    if (
      searchInputValue &&
      searchInputValue.split("").length > 1 &&
      value &&
      value.length === 0
    ) {
      if (searchInputValue.toLowerCase().includes("0x")) {
        console.log("check for api", searchInputValue);
        getTokenDetails(searchInputValue.toLowerCase());
      }
    }
  }, [searchInputValue]);

  const getTokenDetails = async (addressOfToken) => {
    try {
      setloading(true);
      const response = await oneInchService.getTokenDetails(addressOfToken);
      const { toToken } = response.data;
      console.log("RESPONSE DATA ", toToken);
      setsearchedToken(toToken);
      setloading(false);
    } catch (error) {
      console.log("error in getTokenDetails", error.message);
      setsearchedToken(null);
      setloading(false);
    }
  };
  return (
    <Modal open={open} onClose={onCloseModal} center>
      <div className="modal-header">
        <h4>Select a token</h4>
      </div>
      <div className="token-modal-wrapper">
        <form>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search name or paste address"
              onChange={(e) => setSearchInputValue(e.target.value)}
              value={searchInputValue}
            />
          </div>
        </form>
        <div className="currency-list">
          <ul>
            {tokens &&
              Object.values(tokens)
                .slice(0, 6)
                .map((token) => {
                  const { symbol, logoURI, address } = token;
                  return (
                    <li
                      onClick={() => selectToken(token, isFrom)}
                      key={address}
                    >
                      <img src={logoURI} alt="currency icon" />
                      <span>{symbol}</span>
                    </li>
                  );
                })}
          </ul>
        </div>
        <div className="divider2"></div>
        <div className="currency-scroll-list">
          <ul>
            {tokens &&
              !value.length &&
              !searchInputValue &&
              Object.values(tokens)
                .slice(6)
                .map((token) => {
                  const { name, symbol, logoURI, address } = token;
                  return (
                    <li
                      onClick={() => selectToken(token, isFrom)}
                      key={address}
                    >
                      <div className="currency-media">
                        <img src={logoURI} alt="currency icon" />
                      </div>
                      <div className="currency-info">
                        <h5>{name}</h5>
                        <p>{symbol}</p>
                      </div>
                    </li>
                  );
                })}

            {tokens &&
              searchInputValue &&
              value &&
              value.length > 0 &&
              value.map((token) => {
                const { name, symbol, logoURI, address } = token;
                return (
                  <li
                    onClick={() => {
                      setSearchInputValue("");
                      selectToken(token, isFrom);
                    }}
                    key={address}
                  >
                    <div className="currency-media">
                      <img src={logoURI} alt="currency icon" />
                    </div>
                    <div className="currency-info">
                      <h5>{name}</h5>
                      <p>{symbol}</p>
                    </div>
                  </li>
                );
              })}
            {searchInputValue &&
              searchInputValue.split("").length > 1 &&
              value &&
              value.length === 0 && (
                <>
                  {loading && searchInputValue.toLowerCase().includes("0x") ? (
                    <>
                      <span className="loading">loading...</span>
                    </>
                  ) : (
                    <>
                      {searchedToken !== null ? (
                        <>
                          <li
                            onClick={() => {
                              setSearchInputValue("");
                              selectToken(searchedToken, isFrom);
                            }}
                            key={searchedToken.address}
                          >
                            <div className="currency-media">
                              <img
                                src={searchedToken.logoURI}
                                alt="currency icon"
                              />
                            </div>
                            <div className="currency-info">
                              <h5>{searchedToken.name}</h5>
                              <p>{searchedToken.symbol}</p>
                            </div>
                          </li>
                        </>
                      ) : (
                        <>
                          <span className="notfound">not found</span>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
          </ul>
        </div>
      </div>
      <div className="modal-footer">
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
          </svg>
          Manage Token Lists
        </button>
      </div>
    </Modal>
  );
};

export default CurrencyTokenModal;
