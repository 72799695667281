import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
const Settingpopup = ({fromToken,slippage,setslippage}) => {
  const [autochecked, setautoChecked] = useState(false);
  const [expertchecked, setexpertChecked] = useState(false);
  const [timeout,setTimeout] = useState(10);

  const handleTimeout = (e)=> {
    e.preventDefault()
    let value = e.target.value;
    if(value< 60){
      setTimeout(value)
    }
  }
  const autohandleChange = () => {
    setautoChecked((autochecked) => !autochecked);
  };

  const experthandleChange = () => {
    setexpertChecked((expertchecked) => !expertchecked);
  };

  const handleAuto = (e) =>{
    e.preventDefault()
    if(fromToken.hasOwnProperty("isCustom")){
      setslippage((prevState) =>  6);
    }else{
      setslippage(parseFloat(1))
    }
  }
  const updateSlippage = (e) =>{
    if(e.target.value<=50){
      setslippage(parseFloat(e.target.value))
    }
  }

  return (
    <div className="setting-popup">
      <h2>Transaction Settings</h2>
      <div className="transaction-setting">
        <div className="st-field">
          <label>Slippage tolerance ?</label>
          <div className="st-inbtn">
            <Button onClick={handleAuto}>Auto</Button>
            <div className="tolerance-value">
              <input value={slippage ? slippage:0} onChange={updateSlippage} type="number" name="slippage-value" placeholder="0.10" />
            </div>
          </div>
        </div>
        <div className="st-field">
          <label>Transaction deadline ?</label>
          <div className="st-inbtn trs-deadline">
            <input value={timeout} onChange={handleTimeout} type="number" name="slippage-value" placeholder="30" />
            <span>minutes</span>
          </div>
        </div>
      </div>
      <div className="interface-setting">
        <h2>interface Settings</h2>
        <div className="auto-filed">
          <span>Auto Router API ?</span>
          <Switch
            checked={autochecked}
            onChange={autohandleChange}
            offColor="#ffffff"
            onColor="#ffffff"
            offHandleColor="#6e727d"
            onHandleColor="#ad964e"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(173, 150, 78, 0.6)"
            activeBoxShadow={false}
            height={40}
            width={66}
            className="react-switch"
            id="material-switch"
          />
        </div>
        <div className="auto-filed">
          <span>Expert Mode ?</span>
          <Switch
            checked={expertchecked}
            onChange={experthandleChange}
            offColor="#ffffff"
            onColor="#ffffff"
            offHandleColor="#6e727d"
            onHandleColor="#ad964e"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(173, 150, 78, 0.6)"
            activeBoxShadow={false}
            height={40}
            width={66}
            className="react-switch"
            id="material-switch"
          />
        </div>
      </div>
    </div>
  );
};
export default Settingpopup;
