export const FETCH_TOKENS = "FETCH_TOKENS"
export const FETCH_TOKENS_SUCCESS = "FETCH_TOKENS_SUCCESS"
export const FETCH_TOKENS_FAILURE = "FETCH_TOKENS_FAILURE"

export const FETCH_QUOTE ="FETCH_QUOTE"
export const FETCH_QUOTE_SUCCESS = "FETCH_QUOTE_SUCCESS"
export const FETCH_QUOTE_FAILURE = "FETCH_QUOTE_FAILURE"

export const DO_SWAP ="DO_SWAP"
export const DO_SWAP_SUCCESS = "DO_SWAP_SUCCESS"
export const DO_SWAP_FAILURE = "DO_SWAP_FAILURE"

export const APPROVE_SPENDER="APPROVE_SPENDER"
export const APPROVE_SPENDER_SUCCESS = "APPROVE_SPENDER_SUCCESS"
export const APPROVE_SPENDER_FAILURE = "APPROVE_SPENDER_FAILURE"

export const APPROVE_ALLOWENCE="APPROVE_ALLOWENCE"
export const APPROVE_ALLOWENCE_SUCCESS = "APPROVE_ALLOWENCE_SUCCESS"
export const APPROVE_ALLOWENCE_FAILURE = "APPROVE_ALLOWENCE_FAILURE"

export const GET_ALLOWENCE="GET_ALLOWENCE"
export const GET_ALLOWENCE_SUCCESS = "GET_ALLOWENCE_SUCCESS"
export const GET_ALLOWENCE_FAILURE = "GET_ALLOWENCE_FAILURE"