import React  from "react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const SlippageModal = ({
    slippage,
    setslippage,
    onCloseModal,
    open,
}) => {
    const handleSubmit = (e) => {
        e.preventDefault()
        onCloseModal()
    }

    const handleChange = (e) => {
        // if (parseFloat(e.target.value) >= 1) {
        //     setslippage(e.target.value)
        // }
        setslippage(e.target.value)
    }

    return (
        <Modal show={open} onHide={onCloseModal}>
            <Form onSubmit={handleSubmit} className="p-3">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Enter slippage</Form.Label>
                    <Form.Control value={slippage} onChange={handleChange} type="number" placeholder="slippage" />
                </Form.Group>
                <Button onClick={onCloseModal} className="btn btn-sm" variant="secondary" type="submit">
                    confirm
                </Button>
            </Form>
        </Modal>
    );
};

export default SlippageModal;