import React, { useState, useEffect } from "react";
import NotFound from "./pages/NotFound";
import { Routes, Route } from "react-router-dom";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import Navigation from "./components/Navbar";
import Home from "./pages/Home";
import "./styles/main.css";
import { useDispatch } from "react-redux";
import * as authActions from "./store/actions/authActions";
import "react-responsive-modal/styles.css";
import "./index.css";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "gold swap", // Required
      infuraId: "3b1a344298fc4f74a2c3c178d965ab58", // Required unless you provide a JSON RPC url; see `rpc` below
    },
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: "3b1a344298fc4f74a2c3c178d965ab58", // required
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
});

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);

  const handleWalletConnect = async () => {
    try {
      const instance = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      const signer = provider.getSigner();
      const accounts = await provider.listAccounts();
      // set in state
      setAccount(accounts[0]);

      const authData = {
        account: accounts[0],
        signer,
        provider,
      };
      // console.log({authData})
      setLoading(false);
      dispatch(authActions.setAuth(authData));
    } catch (error) {
      console.log("error in handleWalletConnect", error.message);
    }
  };

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") handleWalletConnect();
  }, []);

  const changeNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        window.ethereum._handleChainChanged({
          chainId: 0x1,
          networkVersion: 1,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    console.log(changeNetwork)
  }, []);

  return (
    <div className="App">
      <div className="backround-radial-gredient"></div>
      <Navigation web3Handler={handleWalletConnect} account={account} />
      <div>
        {loading ? (
          <div>
            <p className="mx-3 text-white my-0">
              Awaiting for wallet Connection...
            </p>
          </div>
        ) : (
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route
              path="/"
              element={
                <Home account={account} web3Handler={handleWalletConnect} />
              }
            />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default App;
