import React from "react";
import "./index.css"
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CurrencyIcon from './../../assets/images/currency-icon.png';
import Button from '@mui/material/Button'; 

const Navigation = (props) => {
  const { web3Handler, account } = props;

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleAccounConnect = async () =>{
    if(account){
      return;
    }
    await web3Handler();
  }
  return (
    <div className="navigation-container">
        <div className="brand-logo">
            <img src={require("../../assets/images/latest-logo-svg.svg")} alt={"logo"} className="logo-image"/>
        </div>
        <div className="d-flex justify-content-end align-items-center">
            {/* <div className="exchange-points">
              <button className="navigation-button inactive"><img src={CurrencyIcon} alt="currency icon" /> Ethereum <KeyboardArrowDownIcon /></button>
            </div> */}
            <div className="menu-dropdown-right dropdown">
              <Button
                  id="basic-button2"
                  onClick={handleClick2} 
                  className="navigation-button inactive"
                  aria-controls={open2 ? 'basic-menu2' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? 'true' : undefined}
              >
                  <img src={CurrencyIcon} alt="currency icon" /> <span>Ethereum</span>
              </Button>
              
            </div>
            <button className="connect-wallet-button" onClick={handleAccounConnect}>
              {
                account ?
                `${account.slice(0,6)}...${account.slice(-4)}`:"connect wallet"
              }
              {/* Connect Wallet */}
            </button>
            {/* <div className="menu-dropdown-right dropdown">
              <Button
                  id="basic-button"
                  className="navigation-button inactive"
                  aria-haspopup="true"
                  onClick={changeNetwork}
              >
                change chain
              </Button>
          
            </div> */}
        </div> 
    </div>
  )

}

export default Navigation;